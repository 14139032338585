import { IconButton, Stack } from '@mui/material'
import { styled } from '@mui/material/styles'
import { SwiperSlide } from 'swiper-v9/react'

// swiperjs doesn't fully support SSR.  Specifically, spaceBetween and slidesPerView are rendered
// on the client-side.  This function creates a CSS object for the SwiperSlide so that the slides
// render with the correct slidesPerView layout and spaceBetween during SSR.
export const generateSsrStyles = (breakpoints, spaceBetweenDefault, slidesPerViewDefault, zoomView) => {
  if (zoomView) {
    return {
      width: '80% !important',
      marginRight: '12%',
      marginLeft: 'calc(-15px + 12%)',
    }
  }
  if (!breakpoints) {
    return {
      width:
        slidesPerViewDefault !== 'auto'
          ? `calc((100% - ${spaceBetweenDefault *
              Math.floor(slidesPerViewDefault - 1)}px)/${slidesPerViewDefault}) !important`
          : 'min-content',
      marginRight: `${spaceBetweenDefault}px`,
    }
  }

  let obj = {}

  // eslint-disable-next-line no-restricted-syntax
  for (const [
    breakpoint,
    { spaceBetween = spaceBetweenDefault, slidesPerView = slidesPerViewDefault },
  ] of Object.entries(breakpoints)) {
    obj = {
      ...obj,
      [`@media (min-width: ${breakpoint}px)`]: {
        width:
          slidesPerView !== 'auto'
            ? `calc((100% - ${spaceBetween * Math.floor(slidesPerView - 1)}px)/${slidesPerView}) !important`
            : 'min-content !important',
        marginRight: `${spaceBetween}px`,
      },
    }
  }
  return obj
}

export const SwiperSlideSsr = styled(SwiperSlide, {
  shouldForwardProp: prop => prop !== 'ssrSwiperSlideStyles',
})(({ ssrSwiperSlideStyles }) => ({
  ...(ssrSwiperSlideStyles ?? {}),
}))

export const ComponentWrapper = styled(Stack, {
  shouldForwardProp: prop => prop !== 'swiperStyles',
})(({ swiperStyles }) => ({
  padding: swiperStyles?.padding ?? 0,
}))

export const DesktopSwiperWrapper = styled('div')(() => ({
  width: '100%',
  height: '100%',
}))

export const BaseSwiperWrapper = styled('div')(
  ({
    theme,
    gapToScrollbarDesktop,
    gapToScrollbarMobile,
    slidesPerViewDesktop,
    slidesPerViewMobile,
    hideScrollbar,
    swiperStyles,
    scrollbarStyles,
    fullWidthDesktop,
    fullWidthDesktopOffset,
    fullWidthMobile,
    fullWidthMobileOffset,
    freeModeMobile,
    navigationVariant,
    isDisplayFilter,
    forceCenter,
  }) => ({
    '@media only screen and (max-width: 104rem)': {
      marginLeft: fullWidthDesktop ? `-${fullWidthDesktopOffset}px` : 0,
      marginRight: fullWidthDesktop ? `-${fullWidthDesktopOffset}px` : 0,
    },
    [theme.breakpoints.down('sm')]: {
      marginLeft: fullWidthMobile ? `-${fullWidthMobileOffset}px` : 0,
      marginRight: fullWidthMobile ? `-${fullWidthMobileOffset}px` : 0,
    },
    '& .swiper-wrapper': {
      backgroundColor: swiperStyles?.backgroundColor ?? 'none',
      paddingBottom: hideScrollbar ? 0 : '1.5rem',
      marginBottom: hideScrollbar ? 0 : gapToScrollbarDesktop ?? '40px',
      [theme.breakpoints.down('sm')]: {
        marginBottom: hideScrollbar ? 0 : gapToScrollbarMobile ?? '14px',
      },
    },
    '& .swiper-button-lock, & .swiper-button-prev, & .swiper-button-next': {
      display: 'none',
    },
    '& .swiper-slide': {
      height: 'auto',
      // width: 'min-content',
      width: slidesPerViewDesktop === 'auto' ? 'min-content' : '100%',
      '&:first-of-type': {
        ...(forceCenter ? { marginLeft: 'auto' } : {}),
      },
      '&:last-of-type': {
        ...(forceCenter ? { marginRight: 'auto' } : {}),
      },
      [theme.breakpoints.down('sm')]: {
        width: slidesPerViewMobile === 'auto' ? 'min-content' : '100%',
        '&:first-of-type': {
          // eslint-disable-next-line no-nested-ternary
          marginLeft: forceCenter ? 'auto' : freeModeMobile && fullWidthMobileOffset ? '15px' : null,
        },
        '&:last-of-type': {
          // eslint-disable-next-line no-nested-ternary
          marginRight: forceCenter ? 'auto' : freeModeMobile && fullWidthMobileOffset ? '15px' : null,
        },
      },
    },
    // '& .swiper-slide-active.swiper-slide:last-child': {
    //   marginLeft: 0,
    // },
    '& .swiper-scrollbar': {
      '--swiper-scrollbar-size': scrollbarStyles?.thickness ? `${scrollbarStyles.thickness}px` : '3px',
      display: hideScrollbar ? 'none' : 'block',
      width: '100%',
      bottom: scrollbarStyles?.thickness ? `${18 - Math.floor(scrollbarStyles.thickness / 2.5)}px` : '18px',
      [theme.breakpoints.up('sm')]:
        navigationVariant === 'default'
          ? {
              right: fullWidthDesktopOffset ? `${120 + fullWidthDesktopOffset}px` : '120px',
              left: `calc(1% + ${fullWidthDesktopOffset ?? 0}px)`,
              width: 'unset',
            }
          : null,
      [theme.breakpoints.down('sm')]: {
        width: '90%',
        left: '5%',
      },
      backgroundColor: `rgba(0, 83, 160, 0.08)`,
      '&-drag': {
        backgroundColor: `rgba(0, 83, 160, 0.5)`,
        '&:hover, &:active': {
          [theme.breakpoints.up('sm')]: {
            cursor: 'pointer',
            backgroundColor: `rgba(0, 83, 160, 0.65)`,
          },
        },
      },
    },
  }),
)

export const NavigationWrapper = styled('div')(({ fullWidthDesktopOffset }) => ({
  width: '100%',
  position: 'absolute',
  right: `${fullWidthDesktopOffset ?? 0}px`,
  bottom: 0,
  zIndex: 20,
  display: 'flex',
  justifyContent: 'flex-end',
}))

export const NavigationButton = styled(IconButton)(() => ({
  backgroundColor: 'rgba(0, 83, 160, 0.04)',
  margin: '0 5px',
  height: '43px',
  width: '43px',
  padding: '5px',
  '& svg': {
    color: '#003566',
    height: '35px',
    width: '35px',
  },
  '&.Mui-disabled': {
    background: 'rgba(0, 83, 160, 0.04)',
    '& svg': {
      color: 'gray',
    },
  },
}))

export const NavigationVariant1Wrapper = styled('div')(({ gapToScrollbarDesktop, hideScrollbar, direction }) => {
  let height
  if (!hideScrollbar) {
    height = `calc(100% - ${gapToScrollbarDesktop ?? '40px'} - 24px)`
  } else {
    height = `calc(100% - 6px)`
  }

  return {
    position: 'absolute',
    right: direction === 'right' ? 0 : null,
    marginRight: direction === 'right' ? '-20px' : null,
    left: direction === 'left' ? 0 : null,
    marginLeft: direction === 'left' ? '-20px' : null,
    top: 0,
    zIndex: 20,
    height,
    display: 'flex',
    alignItems: 'center',
  }
})

export const NavigationButtonVariant1 = styled(IconButton, {
  shouldForwardProp: prop => prop !== 'hideButton',
})(({ hideButton }) => ({
  backgroundColor: 'black',
  margin: '0 5px',
  height: '43px',
  width: '43px',
  padding: '5px',
  transition: '0.15s all cubic-bezier(0.4, 0, 0.2, 1)',
  opacity: hideButton ? 0 : 1,
  '&:hover, &:active': {
    backgroundColor: 'rgb(50, 50, 50)',
  },
  '& svg': {
    color: 'white',
    height: '35px',
    width: '35px',
  },
}))
